import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import storage from 'store2'
import UserModel from '@/models/user'
import { isWeiXin, isAdSite } from '@/utils/ua'
import { useCommonStore } from './common'

const TOKEN = 'token'

export const useUserStore = defineStore('user', () => {
  const user = ref(null) // 初始化时是null，有过数据后哪怕清除也会赋值{}，便于顶部展示登录状态
  const session = ref(null)
  const commonStore = useCommonStore()

  const isLogined = computed(() => !!session.value)
  const Authorization = computed(() =>
    session.value?.access_token ? `Bearer ${session.value?.access_token}` : ''
  )

  function setUser(value) {
    user.value = value
  }

  function setSession(value) {
    session.value = value
    storage(TOKEN, value)
  }

  function clearSession() {
    storage.remove(TOKEN)
    session.value = null
    user.value = {}
  }
  function restoreSession() {
    if (!session.value) {
      const token = storage(TOKEN)
      if (token) {
        setSession(token)
      }
    }
  }

  async function refreshUser() {
    try {
      const user = await UserModel.getUserInfo()
      setUser(user)
    } catch (err) {
      setUser({})
      return Promise.reject(err)
    }
  }

  function checkLogin(redirectUri) {
    if (!session.value) {
      if (isWeiXin && !isAdSite) {
        const url = new URL(location.href)
        const token = url.searchParams.get('token')

        if (token) {
          setSession({ access_token: token })
          location.replace(url.pathname)
        } else {
          const redirect_uri = encodeURIComponent(
            import.meta.env.VITE_API_URL + '/api/wx/getWxAuthInfo'
          )
          const state = encodeURIComponent(redirectUri || location.href)
          const appid = import.meta.env.VITE_WEIXIN_APP_ID
          location.replace(
            `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`
          )
        }
      } else {
        // 非微信浏览器登录逻辑
        commonStore.showLoginBox = true
      }
      return false
    }
    return true
  }

  return {
    user,
    session,
    isLogined,
    Authorization,
    setUser,
    setSession,
    clearSession,
    restoreSession,
    refreshUser,
    checkLogin
  }
})
