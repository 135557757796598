import http from '@/utils/http'
import axios from '@/utils/axios'
import { replaceHost } from '@/utils/file'

function format(data, isPreview) {
  if (data.template?.content && !data.template.content.gap) {
    data.template.content.gap = 24
  }
  if (data.content) {
    data.content.content.forEach((item) => {
      if (['title', 'paragraph'].includes(item.type)) {
        const style = item.style
        // style.color = style.color ?? '#222'
        // style.fontSize = style.fontSize ?? 22
        style.textAlign = style.textAlign ?? 'left'
        style.justifyContent = style.justifyContent ?? 'center'
      }
      if (isPreview) {
        replaceHost(item, 'src')
        replaceHost(item, 'thumb')
        replaceHost(item, 'component')
      }
    })
  }
  if (isPreview && data.template) {
    const { config } = data.template
    replaceHost(config, 'backgroundImage')
    replaceHost(config, 'topImage')
    replaceHost(config.component, 'title')
    replaceHost(config.component, 'paragraph')
  }
  // if (isPreview && data.shareInfo) {
  //   replaceHost(data.shareInfo, 'thumb')
  //   replaceHost(data.shareInfo, 'defaultThumb')
  //   replaceHost(data.shareInfo, 'uploadThumb')
  // }
}

export default {
  async subTask(options) {
    return await http.post('/api/ai/subTask', options)
  },

  async saveWork(options) {
    return await http.post('/api/userwork/create', options)
  },

  async updateWork({ id, ...options }) {
    return await http.put(`/api/userwork/upContent/${id}`, options)
  },

  async getWorkById({ id }) {
    const data = await http.get(`/api/userwork/get/${id}`)
    format(data)
    return data
  },

  // async getWorkByCode({ code }) {
  //   return await http.get(`/api/userwork/getByCode/${code}`)
  // },

  async getPreviewByCode({ code }) {
    const data = await http.get(`/api/s/${code}`)
    format(data, true)
    return data
  },

  async getWorkList(options) {
    const data = await http.get(`/api/userwork/find`, options)
    data.list?.forEach((item) => format(item))
    return data
  },

  delWork(id) {
    return http.delete(`/api/userwork/del/${id}`)
  },
  getShareHost() {
    return http.get(`/api/host/get`)
  },
  getQrDomain() {
    return http.get(`/api/domain/getQrDomain`)
  },
  getShareDomain(userId) {
    return http.get(`/api/domain/getShareDomain`, { userId })
  },
  dau(options) {
    return axios.post(`/api/statistics/dau`, options, {
      ignoreMessage: true
    })
  },
  getWorkStatsByTime(options) {
    return http.get(`/api/statistics/getWorkStatsByTime`, options)
  },
  getWorkStats({ code }) {
    return http.get(`/api/statistics/getWorkStats/${code}`)
  },
  getWorkStatsPv({ code }) {
    return http.get(`/api/statistics/getWorkPv/${code}`)
  }
}
