import { createRouter, createWebHistory } from 'vue-router'
import { useCommonStore } from '@/stores/common'
import { useUserStore } from '@/stores/user'
import { track } from '@/utils/track'
import { isMobile, isWeiXin, isAdSite } from '@/utils/ua'
import { getJsApiSignature } from '@/utils/wx'

const PcPageHome = () => import('../views/pc/PageHome.vue')
const PcPageWork = () => import('../views/pc/PageWork.vue')
const PcPageEdit = () => import('../views/pc/PageEdit.vue')
const PcPageData = () => import('../views/pc/PageData.vue')
const MobilePageReport = () => import('../views/mobile/PageReport.vue')
const MobilePageHome = () => import('../views/mobile/PageHome.vue')
const MobilePageWork = () => import('../views/mobile/PageWork.vue')
const MobilePageEdit = () => import('../views/mobile/PageEdit.vue')
const MobilePagePreview = () => import('../views/mobile/PagePreview.vue')
const MobilePageData = () => import('../views/mobile/PageData.vue')

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      meta: {
        title: isAdSite ? 'H5制作工具' : '秒作',
        ignoreLogin: true,
        ignoreUserInfo: isMobile || isAdSite,
        trackKey: `aih5_${isMobile ? 'mobile' : 'pc'}_pageView_home`
      },
      component: isMobile ? MobilePageHome : PcPageHome,
      beforeEnter: (to) => {
        const userStore = useUserStore()
        if (isWeiXin && !userStore.isLogined) {
          const { token } = to.query
          if (token) {
            userStore.setSession({ access_token: token })
            return { path: to.path, query: {} }
          }
        }
      }
    },
    {
      path: '/work',
      meta: {
        title: '我的作品',
        trackKey: `aih5_${isMobile ? 'mobile' : 'pc'}_pageView_work`,
        ignoreUserInfo: isMobile || isAdSite
      },
      component: isMobile ? MobilePageWork : PcPageWork
    },
    {
      path: '/edit/:id',
      meta: {
        title: '',
        ignoreLogin: true,
        trackKey: `aih5_${isMobile ? 'mobile' : 'pc'}_pageView_edit`,
        ignoreWeiXinShare: true,
        ignoreUserInfo: true
      },
      component: isMobile ? MobilePageEdit : PcPageEdit
    },
    {
      path: '/data/:code',
      meta: {
        title: '作品数据',
        ignoreUserInfo: isMobile || isAdSite
      },
      component: isMobile ? MobilePageData : PcPageData
    },
    {
      path: '/report/:code',
      meta: {
        title: '访问数据',
        ignoreLogin: true,
        ignoreSideBar: true,
        ignoreUserInfo: true
      },
      component: MobilePageReport
    },
    {
      name: 'preview',
      path: '/:page/:code/:option*',
      meta: {
        title: '',
        ignoreLogin: true,
        ignoreSideBar: true,
        ignoreWeiXinShare: true,
        ignoreUserInfo: true
      },
      component: MobilePagePreview
    }
  ]
})

router.beforeEach(async (to) => {
  const userStore = useUserStore()
  userStore.restoreSession()
  if (to.path === '/' && isAdSite) {
    return '/work'
  }
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.query.from) {
    localStorage.setItem('from', to.query.from)
  }
  if (!to.meta.ignoreLogin) {
    const isLogined = userStore.checkLogin(location.origin + to.href)
    if (isLogined) {
      if (!to.meta.ignoreUserInfo) {
        try {
          await userStore.refreshUser()
        } catch (err) {
          console.warn(err)
          return false
        }
      }
    } else {
      return false
    }
  } else {
    if (!to.meta.ignoreUserInfo) {
      try {
        await userStore.refreshUser()
      } catch (err) {
        console.warn(err)
      }
    }
  }
})

router.afterEach(async (to, from) => {
  const commonStore = useCommonStore()
  if (to.meta.trackKey) {
    track(to.meta.trackKey)
  }
  if (!to.meta.ignoreWeiXinShare) {
    getJsApiSignature()
  }

  // from.href没有值，说明是刷新页面，则不处理，否则刚弹出的框就被隐藏了
  if (!isMobile && from.href && from.href !== to.href) {
    commonStore.showLoginBox = false
    setTimeout(() => {
      document.body.querySelectorAll('.ui-dialog').forEach((item) => {
        if (!item.className.includes('global')) {
          item.remove()
        }
      })
    })
  }
})

router.onError((err) => {
  console.error(err)
  ElMessageBox.alert('出错啦！服务器开了小差，请刷新页面重试。', '错误提示').then(() => {
    location.reload()
  })
})

export default router
